import classnames from 'clsx';
import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import { Component } from 'react';
import connectWithPlayer from 'src/common/decorators/connectWithPlayer';
import withNowPlayingContext from '../../../decorators/withNowPlayingContext';
import hasNoBehavior from '../../../utils/guideItem/hasNoBehavior';
import isLocked from '../../../utils/guideItem/isLocked';
import isPlayable from '../../../utils/guideItem/isPlayable';
import isSelectable from '../../../utils/guideItem/isSelectable';
import shouldNavigate from '../../../utils/guideItem/shouldNavigate';
import GuideItemLink from './GuideItemLink';
import LockedTile from './LockedTile';
import css from './guide-item.module.scss';

export class GuideItemTitle extends Component {
  static propTypes = {
    children: PropTypes.node,
    guideItem: PropTypes.object.isRequired,
    style: PropTypes.object,
    playerActions: PropTypes.object.isRequired,
    syncedPlayerStatus: PropTypes.string.isRequired,
    syncedPlayerAction: PropTypes.func.isRequired,
    isSyncedWithPlayer: PropTypes.bool.isRequired,
    isNowPlayingOpen: PropTypes.bool.isRequired,
    nowPlayingContext: PropTypes.object.isRequired,
    selectedContentId: PropTypes.string,
    canScrub: PropTypes.bool,
    hasImage: PropTypes.bool,
    hasOwnTitleWrapper: PropTypes.bool,
    isClickHandlerEnabled: PropTypes.bool,
    className: PropTypes.string,
  };

  handleGuideItemLinkClick = (to, event) => {
    const {
      guideItem,
      playerActions,
      syncedPlayerAction,
      isSyncedWithPlayer,
      isNowPlayingOpen,
      canScrub,
      nowPlayingContext,
    } = this.props;

    if (!isNowPlayingOpen) {
      // we want to block navigation when nowPlaying is NOT open
      // because this function is only passed to GuideItemLink below
      // when the item is playable (i.e. topic/episode) or when in nowPlaying
      // and shouldNavigate returns true
      event.preventDefault();
    }

    // when in nowplaying and currently playing item is clicked
    // we want to only scroll and NOT control playback or retune
    if (isSyncedWithPlayer && isNowPlayingOpen) {
      nowPlayingContext.scrollToTop();
      return;
    }

    if (isSyncedWithPlayer) {
      syncedPlayerAction(playerActions, canScrub);
    } else {
      playerActions.tune({ guideItem });
      if (isNowPlayingOpen) {
        nowPlayingContext.scrollToTop();
      }
    }
  };

  render() {
    const {
      guideItem,
      children,
      selectedContentId,
      hasImage,
      isNowPlayingOpen,
      hasOwnTitleWrapper,
      isClickHandlerEnabled,
      className,
    } = this.props;

    if (!children) {
      return null;
    }

    const titlesClass = classnames(className, {
      [css.guideItemTitlesContainer]: !hasOwnTitleWrapper,
      [css.guideItemTitlesContainerNoImage]: !hasImage,
    });

    /* No anchor */
    if (hasNoBehavior(guideItem) || isSelectable(guideItem)) {
      return <div className={titlesClass}>{children}</div>;
    }

    /* If should show the upsell dialog */
    if (isLocked(guideItem)) {
      return (
        <LockedTile
          dataTestId="lockedTitle"
          guideItem={guideItem}
          className={titlesClass}
          isClickHandlerEnabled={isClickHandlerEnabled}
        >
          {children}
        </LockedTile>
      );
    }

    /* If should link to a profile or another browse page */
    if (shouldNavigate(guideItem, selectedContentId)) {
      return (
        <GuideItemLink
          dataTestId="guideItemTitleLink"
          guideItem={guideItem}
          className={titlesClass}
          onClick={isNowPlayingOpen ? this.handleGuideItemLinkClick : undefined}
        >
          {children}
        </GuideItemLink>
      );
    }

    /* If it's playable then anchor so tune begins on click */
    if (isPlayable(guideItem)) {
      return (
        <GuideItemLink
          dataTestId="guideItemTitleLink"
          onClick={this.handleGuideItemLinkClick}
          guideItem={guideItem}
          className={titlesClass}
        >
          {children}
        </GuideItemLink>
      );
    }

    /* Default anchor to browse or profile */
    return (
      <GuideItemLink
        dataTestId="guideItemTitleLink"
        guideItem={guideItem}
        className={titlesClass}
      >
        {children}
      </GuideItemLink>
    );
  }
}

export default flow(connectWithPlayer, withNowPlayingContext)(GuideItemTitle);
