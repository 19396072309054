import classnames from 'clsx';
import PropTypes from 'prop-types';
import ClampText from '../../shared/ClampText';
import css from './guide-item.module.scss';
import titlesCss from './titles.module.scss';

export const Titles = ({
  title,
  titleClassName,
  subtitle,
  subtitleClassName,
  titleSize,
  titleMaxLineCount,
  titleAlignment,
  subtitleSize,
  subtitleMaxLineCount,
}) => {
  if (!title) {
    return null;
  }

  const titleLineCount = Number.parseInt(titleMaxLineCount, 10);
  const subtitleLineCount = Number.parseInt(subtitleMaxLineCount, 10);
  return (
    <div
      data-testid="guideItemTitleContainer"
      className={classnames({
        [css[`textAlign${titleAlignment}`]]: !!titleAlignment,
        [css[`guideItemTitleContainer${titleSize}`]]: !!titleSize,
      })}
    >
      <div
        data-testid="guideItemTitle"
        className={classnames(
          css.guideItemTitleOffset,
          css.guideItemTitleSingleLine,
          { [css.guideItemTitleMultiLine]: titleLineCount > 1 },
          { [css[`guideItemTitleSize${titleSize}`]]: !!titleSize },
          titleClassName,
        )}
      >
        <ClampText clamp={titleLineCount} className={titlesCss.titleText}>
          {title}
        </ClampText>
      </div>
      {subtitle && (
        <div
          data-testid="guideItemTitleSubtitle"
          className={classnames(
            css.guideItemTitleOffset,
            {
              [css.guideItemSubtitle]: subtitleLineCount === 1,
              [css.guideItemSubtitleMultiLine]: subtitleLineCount > 1,
              [css[`guideItemSubtitleSize${subtitleSize}`]]: !!subtitleSize,
            },
            subtitleClassName,
          )}
        >
          <ClampText
            clamp={subtitleLineCount}
            className={titlesCss.subTitleText}
          >
            {subtitle}
          </ClampText>
        </div>
      )}
    </div>
  );
};

Titles.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  titleSize: PropTypes.string,
  titleClassName: PropTypes.string,
  titleMaxLineCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  titleAlignment: PropTypes.string,
  subtitleSize: PropTypes.string,
  subtitleClassName: PropTypes.string,
  subtitleMaxLineCount: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
};

Titles.defaultProps = {
  titleMaxLineCount: 1,
  subtitleMaxLineCount: 1,
};

export default Titles;
