import PropTypes from 'prop-types';
import css from './badge.module.scss';

export function Badge({ style = {}, dataTestId, children }) {
  return (
    <div data-testid={dataTestId} className={css.badge} style={style}>
      {children}
    </div>
  );
}

Badge.propTypes = {
  dataTestId: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
};
