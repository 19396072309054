import { behaviors } from '../guideItemTypes';

const navigationActionItems = {
  [behaviors.profile.toLowerCase()]: true,
  [behaviors.browse.toLowerCase()]: true,
};

export default function shouldNavigate(guideItem, selectedContentId) {
  const { actionName: camelCaseActionName } =
    guideItem?.behaviors?.default || {};
  const actionName = camelCaseActionName?.toLowerCase();

  if (!navigationActionItems[actionName]) {
    return false;
  }

  const action = guideItem?.actions?.[actionName];

  return action?.destinationInfo?.id !== selectedContentId;
}
