import classNames from 'clsx';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectIsFord } from '../../../../selectors/app';
import commonCss from '../../../../styles/common.module.scss';
import assetUrl from '../../../../utils/assetUrl';
import { isDiscordMode } from '../../../../utils/discord';
import hasNoBehavior from '../../../../utils/guideItem/hasNoBehavior';
import { programTypes } from '../../../../utils/guideItemTypes';
import ImageWithDefault from '../../../shared/ImageWithDefault';
import GuideItemTitle from '../../shared/GuideItemTitle';
import Titles from '../../shared/Titles';
import WithGuideItemActions from '../../shared/WithGuideItemActions';
import css from '../../shared/guide-item.module.scss';

const Default = ({
  guideItem,
  selectedContentId,
  index,
  isClickHandlerEnabled,
  containerClassName,
  imageWrapperClassName,
  getStyles,
  isExtendedInfoView,
}) => {
  const isFord = useSelector(selectIsFord);

  const {
    accessibilityTitle,
    style,
    properties,
    guideId,
    title,
    subtitle,
    image,
  } = guideItem;
  const {
    tileSize,
    tileShape,
    titleSize,
    tileTitleAlignment,
    titleMaxLineCount,
    subtitleSize,
    subtitleMaxLineCount,
  } = getStyles(style);
  const { contentInfo, seoInfo } = properties || {};
  const nextTitle = seoInfo?.title || '';
  const guideItemImageWrapperClassNames = classNames(
    css.guideItemImageWrapper,
    css[`guideItemImageWrapper${tileSize}`],
    imageWrapperClassName,
  );
  const badgeImageClassNames = classNames(css.badgeImage, {
    [css[`badgeImage${tileSize}`]]: tileSize,
  });

  const applyBadge = (url, alt) => (
    <img className={badgeImageClassNames} src={assetUrl(url)} alt={alt} />
  );

  return (
    <div
      className={classNames(
        'row',
        css.guideItemContainer,
        {
          [css.noMarginTop]: !isExtendedInfoView && index === 0,
        },
        containerClassName,
      )}
      data-testid={`guideItem-${index}`}
      data-testtype="defaultTile"
      data-nexttitle={nextTitle}
      data-nextguideitem={guideId}
    >
      <div
        data-testid="defaultTileOverlay"
        className={
          isClickHandlerEnabled || hasNoBehavior(guideItem)
            ? ''
            : commonCss.overlay
        }
      >
        {image && (
          <div
            data-testid="guideItemImageWrapper"
            className={guideItemImageWrapperClassNames}
          >
            <WithGuideItemActions
              guideItem={guideItem}
              className={css.guideItemPlayButtonContainer}
              iconFontSize="30px"
              tileSize={tileSize}
              tileShape={tileShape}
              isClickHandlerEnabled={isClickHandlerEnabled}
            >
              {!isFord &&
                contentInfo?.isPremium &&
                contentInfo?.type === programTypes.audiobook &&
                applyBadge(
                  'assets/img/shared/premium-badge.svg',
                  'Premium Station Icon',
                )}
              {/* The Discord check can be removed when PLATFORM-17505 is resolved */}
              {!contentInfo?.isPremium &&
                contentInfo?.isBoostStation &&
                !isDiscordMode() &&
                applyBadge(
                  'assets/img/shared/boost-badge.svg',
                  'Switch Station Icon',
                )}
              <ImageWithDefault
                dataTestId="guideItemImage"
                className={css.guideItemImageFill}
                src={image}
                alt={accessibilityTitle || title}
              />
            </WithGuideItemActions>
          </div>
        )}
        <GuideItemTitle
          guideItem={guideItem}
          selectedContentId={selectedContentId}
          hasImage={!!image}
          isClickHandlerEnabled={isClickHandlerEnabled}
        >
          <Titles
            title={title}
            subtitle={subtitle}
            titleSize={titleSize}
            titleMaxLineCount={titleMaxLineCount}
            titleAlignment={tileTitleAlignment}
            subtitleSize={subtitleSize}
            subtitleMaxLineCount={
              Number.parseInt(subtitleMaxLineCount, 10) || 1
            }
          />
        </GuideItemTitle>
      </div>
    </div>
  );
};

Default.propTypes = {
  guideItem: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  selectedContentId: PropTypes.string,
  isClickHandlerEnabled: PropTypes.bool,
  containerClassName: PropTypes.string,
  imageWrapperClassName: PropTypes.string,
  isExtendedInfoView: PropTypes.bool,
  getStyles: PropTypes.func,
};

Default.defaultProps = {
  guideItem: {},
  getStyles: () => ({}),
  isClickHandlerEnabled: false,
};

export default Default;
