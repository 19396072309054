import PropTypes from 'prop-types';
import { Component } from 'react';
import ClampedText from 'react-dotdotdot';
import EventListener from 'react-event-listener';

export default class ClampText extends Component {
  static propTypes = {
    clamp: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ]).isRequired,
    children: PropTypes.node.isRequired,
    breakpoint: PropTypes.number,
    className: PropTypes.string,
    truncationHTML: PropTypes.string,
    useNativeClamp: PropTypes.bool,
  };

  state = {
    clampedTextKey: 0,
  };

  componentDidUpdate(prevProps) {
    const { breakpoint, clamp } = this.props;
    if (prevProps.clamp !== clamp || prevProps.breakpoint !== breakpoint) {
      this.handleResize();
    }
  }

  handleResize = () => {
    // Alternate the clamped text key here so that ClampedText re-renders
    // the text and truncates it correctly while the window size is increasing.
    this.setState({ clampedTextKey: this.state.clampedTextKey ? 0 : 1 });
  };

  render() {
    const { children, clamp, className, truncationHTML, useNativeClamp } =
      this.props;
    const { clampedTextKey } = this.state;

    return (
      <EventListener
        target="window"
        onResize={this.handleResize}
        onLoad={this.handleResize}
      >
        <ClampedText
          clamp={clamp}
          className={className}
          truncationHTML={truncationHTML}
          useNativeClamp={useNativeClamp || true}
          key={clampedTextKey}
        >
          {children}
        </ClampedText>
      </EventListener>
    );
  }
}
