import classNames from 'clsx';
import css from './prompt.module.scss';

// Due to note below, since a Prompt may operate on either a guideItem or containerItem, let's make
// sure one or the other is specified.
function itemPropValidation(props) {
  if (!props.guideItem && !props.containerItem) {
    return new Error("at least one of 'guideItem' or 'containerItem' required");
  }

  return undefined;
}

// NOTE: we're looking at both guideItem and containerItem props because Prompts may be utlized on
// the list of containers level (containerItems), or list of guide items level (list layout), where
// containerItem and guideItem nomenclature are used, respectively, to denote an item to be
// rendered.
const Prompt = ({ guideItem, containerItem, isDiscord }) => {
  const item = guideItem || containerItem;

  if (!item?.title) {
    return null;
  }

  return (
    <div className={classNames('row', css.container)}>
      <div className="col-xs-offset-1 col-xs-10 col-lg-offset-3 col-lg-6">
        <div
          id="prompt"
          className={classNames(css.prompt, {
            [css.isDiscord]: isDiscord,
          })}
        >
          <div
            className={classNames(css.text, {
              [css.isDiscord]: isDiscord,
            })}
            data-testid="promptText"
          >
            {item.image && (
              <div>
                <img
                  src={item.image}
                  alt={item?.accessibilityTitle || item.title}
                  className={css.thumb}
                />
              </div>
            )}
            {item.title}
          </div>
        </div>
      </div>
    </div>
  );
};

Prompt.propTypes = {
  guideItem: itemPropValidation,
  containerItem: itemPropValidation,
};

export default Prompt;
