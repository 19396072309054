import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PREMIUM_LOGO_TEXT } from 'src/common/constants/localizations/premium';
import cssVars from 'src/common/styles/variables';
import { openUpsellAndTrackActivity } from '../../../actions/dialog';
import { labels } from '../../../constants/analytics/categoryActionLabel/subscribe';
import { BYPASS_UPSELL_DIALOG_ENABLED } from '../../../constants/experiments/dialog';
import { LocationAndLocalizationContext } from '../../../providers/LocationAndLocalizationProvider';
import { selectExperiment } from '../../../selectors/config';
import debounce from '../../../utils/debounce';
import getSubscribeEventLabel from '../../../utils/subscription/getSubscribeEventLabel';
import createAndOpenSubscribeLink from '../../utils/createAndOpenSubscribeLink';
import css from './premiumLogo.module.scss';
const SOURCE = 'premium.logo';

/* eslint-disable max-len */
export class PremiumLogo extends Component {
  static propTypes = {
    guideId: PropTypes.string.isRequired,
    actions: PropTypes.object.isRequired,
    fill: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    viewBox: PropTypes.string,
    products: PropTypes.array,
    image: PropTypes.string,
    title: PropTypes.string,
    isPremiumUpsellBypassEnabled: PropTypes.bool,
  };

  static contextType = LocationAndLocalizationContext;

  constructor(props, context) {
    super(props, context);
    this.handleClick = debounce(this.handleClick.bind(this));
  }

  handleClick() {
    const {
      guideId,
      products,
      image,
      title,
      actions,
      isPremiumUpsellBypassEnabled,
    } = this.props;
    const { location } = this.context;

    if (isPremiumUpsellBypassEnabled) {
      createAndOpenSubscribeLink({ guideId }, location, products, SOURCE);
      return;
    }

    const eventLabel = getSubscribeEventLabel(
      labels.profileUpsellDialog,
      location,
    );
    actions.openUpsellAndTrackActivity(
      eventLabel,
      guideId,
      null,
      products,
      image,
      title,
    );
  }

  render() {
    const { height, width, viewBox, fill } = this.props;
    const { getLocalizedText } = this.context;
    const strokeFill = fill || cssVars['--content-area-background-color-hex'];

    return (
      <div className={css.premiumLogoWrapper} onClick={this.handleClick}>
        <span className={css.premiumLogoText}>
          {getLocalizedText(PREMIUM_LOGO_TEXT)}
        </span>
        <div className={css.premiumLogo}>
          <svg
            width={width || '7'}
            height={height || '7'}
            viewBox={viewBox || '0 0 8 8'}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.59578 3.85645H1.48926V7.26079H6.59578V3.85645Z"
              stroke={strokeFill}
              strokeWidth="1.25"
              strokeMiterlimit="10"
            />
            <path
              d="M2.62402 3.85665V2.15448C2.62402 1.37187 3.25663 0.739258 4.03924 0.739258C4.82185 0.739258 5.45446 1.37187 5.45446 2.15448V3.85665"
              stroke={strokeFill}
              strokeWidth="1.25"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openUpsellAndTrackActivity,
      },
      dispatch,
    ),
  };
}

function mapStateToProps(state) {
  return {
    isPremiumUpsellBypassEnabled: selectExperiment(
      state,
      BYPASS_UPSELL_DIALOG_ENABLED,
      false,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PremiumLogo);
